import { combineReducers } from 'redux';

import app from './app/reducer';
import leads from './leads/reducer';
import auth from './auth/reducer'


export default combineReducers({
  app,
  auth,
  leads
});


