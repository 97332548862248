import produce from 'immer';
import { FETCH_LEADS_REQUEST , FETCH_LEADS_SUCCESS, FETCH_LEADS_FAILURE, SAVE_SELECTED_PAGE, SET_FORM_COMPLETE, SET_FORM_INCOMPLETE, SAVE_CAMPAIGN_ID } from './constants';

export const initialState = {
  loading: { isLoading : false  },
  leads: [],
  currentFormId: null,
  selectedPage : null,
  errors: false,
  loggedIn : false,
  formComplete : false,
  campaignId : 0
};

const reducer = (state = initialState, action) => { return produce(state, (draftState) => {
    switch(action.type) {

        case FETCH_LEADS_REQUEST:
            draftState.loading.isLoading = true;
            break;

        case FETCH_LEADS_SUCCESS:
            draftState.leads = action.payload.data;
            draftState.currentFormId = action.payload.formId;
            draftState.loading.isLoading = false;
            break;

        case FETCH_LEADS_FAILURE:
            draftState.loading.isLoading = false;
            break;    
            
        case SAVE_SELECTED_PAGE:
            draftState.selectedPage = action.payload;
            draftState.loggedIn = true;
            localStorage.setItem('access_token', action.payload.access_token);
            localStorage.setItem('selectedPageId', action.payload.id);
            break;   

        case SAVE_CAMPAIGN_ID:
            draftState.selectedPage = action.payload;
            draftState.loggedIn = true;
            localStorage.setItem('campaignId', action.payload);
            break;   
            
        case SET_FORM_COMPLETE:
            draftState.formComplete = true;
            localStorage.setItem('formComplete', 'true');
            break; 

        case SET_FORM_INCOMPLETE:
            draftState.formComplete = false;
            localStorage.setItem('formComplete', 'false');
            break;       

        default:

    }
})};

export default reducer;
