import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as leadsSelectors from './../../state/leads/selectors'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Api from '../../state/Api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import * as leadsActions from './../../state/leads/actions';
import * as appActions from './../../state/app/actions';
import sha256 from 'crypto-js/sha256';


const Leads = (props) => {

  const actionSourceOptions = ['Initial Lead from FB','Marketing Qualified Lead','Sales Opportunity','Converted'];
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [pixelsList, setPixelsList] = React.useState([]);
  const [selectedPixel, setSelectedPixel ] = React.useState(null);
  const leads = useSelector(leadsSelectors.getLeads());
  const [currentLead, setCurrentLead ] = useState({full_name: '', email : '', phone_number:''});
  const [FbFormsList, setFbFormsList] = React.useState([]);
  const [selectedForm, setSelectedForm] = React.useState('')
  const [selectedEventName, setSelectedEventName ] = React.useState(null);
  const [selectedResult, setSelectedResult ] = React.useState(0);
  const [resultCodeList, setResultCodeList ] = React.useState([]) 

  const textFieldBoxStyle = {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: 0,
    listStyle: 'none',
    width : '100%'
  }

  const textFieldStyle = {
    width : '100%'
  }

  const leadFormControlStyle = {
    width : '100%'
  }

  const interpretError = (error) => {
    if(error.code === 190 && error.error_subcode === 463 ){
      navigate('/login')
      dispatch( appActions.addToast('Ha caducado el token','error') )
    }
  }

  const getResultCodes = async () => {
    let campaignId = localStorage.getItem('campaignId');
    const { data } = await Api.req.get(`campaigns/${campaignId}/resultcodes`);

    setResultCodeList(data.data)
  }

  const getPixels = () => {
    let access_token =  localStorage.getItem('access_token');
    let bussinesId = localStorage.getItem('businessId');
    window.FB.api(`/${bussinesId}?access_token=${access_token}&fields=adspixels{name,id}`, function(res) {
      console.log(res)
      if(res.error){
        interpretError(res.error)
      }else{
        setPixelsList(res.adspixels.data)
      }
      
    })
  }

  const getForms = () => {
    let access_token =  localStorage.getItem('access_token');
    let selectedPageId = localStorage.getItem('selectedPageId');
   

    window.FB.api(`/${selectedPageId}?access_token=${access_token}&fields=leadgen_forms`, function(res) {
      let data = []
      if(res.error){
        interpretError(res.error)
      }else{
        data = res.leadgen_forms.data
        setFbFormsList(data)
      }
    
    })
  }

  const handleChangeSelectedPixel = event => {
    setSelectedPixel(event.target.value);
  }

  let { formId } = useParams();

  useEffect(  () => {
    let selectedPageId = localStorage.getItem('selectedPageId');
    dispatch(leadsActions.fetchLeads(formId, selectedPageId, selectedResult ))
    dispatch(leadsActions.formComplete())
    setSelectedForm(formId)
    setCurrentLead({full_name: '', email : '', phone_number:''})
    getForms()
    getPixels()
    getResultCodes()
  } , [formId] )
 

  const handleChangeForm = event => {
    console.log(event.target.value);
    navigate('/' + event.target.value + '/leads');
  }

  const handleChangeSelectedEventName = event => {
    setSelectedEventName(event.target.value)
  }  
  
  const handleChangeResult = event => {
    setSelectedResult(event.target.value)
    let selectedPageId = localStorage.getItem('selectedPageId');
    dispatch(leadsActions.fetchLeads(formId, selectedPageId, event.target.value))
  }


  const sendLead = () => {
    let access_token =  localStorage.getItem('access_token');
    let pixelState = resultCodeList.find((elem) => elem.codigo == selectedEventName ).pixelState;

    let params = { 
      "data": [
        {
          event_name: pixelState,
          event_time : Math.floor( Date.now() / 1000),
          action_source : "system_generated",
          user_data: {
              "lead_id": currentLead.lead_id,
              "em": [
                String(sha256(currentLead.email)) 
              ],
              "fn": [
                  String(sha256(currentLead.full_name)) 
              ],
              "ph": [
                  String(sha256(currentLead.phone_number)) 
              ]

          }
        }]
    }

    window.FB.api(`${selectedPixel}/events?access_token=${access_token}`, 'post' , params , function(res) {

      Api.req.put(`leads/lead/${currentLead.lead_id}`, { status: selectedEventName } );
      dispatch(appActions.addToast('Se ha enviado el lead'))
      
    })
  }

  const handleLeadClick = (lead) => {
    setCurrentLead(lead);
  }

  const checkFields = () => {
    if(selectedEventName !== null && selectedPixel !== null ){
      return false;
    }else{
      return true;
    }
    
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box  style={textFieldBoxStyle}>
            <Select
              style={textFieldStyle}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedForm}
              label="Fprm"
              onChange={handleChangeForm}
            >
          
            { FbFormsList.map( (form) => (
              <MenuItem value={form.id}>{form.name}</MenuItem>
            )) } 
            </Select>
          </Box>
          <Box style={textFieldBoxStyle}>
            <Select
              style={textFieldStyle}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedResult}
              label="Fprm"
              onChange={handleChangeResult}
            >
              
              {resultCodeList.map((code) => (
                <MenuItem value={code.codigo}>{code.descripcion}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            {leads.map((lead,index) => (
                    <ListItem style={ currentLead.id === lead.id ? { backgroundColor: '#d9d9d9'} : {}} button onClick={() => handleLeadClick(lead)} key={index}>
                      <ListItemIcon>
                          <PersonIcon></PersonIcon>
                      </ListItemIcon>
                      <ListItemText primary={
                        <React.Fragment>
                          <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="primary">
                            {lead.id}
                          </Typography>
                        </React.Fragment>
                      } 
                      />
                    </ListItem>
              ))}
          </Box>
            
        </Grid>
        { currentLead.email !== '' ?  
        <Grid item xs={4}>
          <Box style={textFieldBoxStyle}>
            <TextField style={textFieldStyle} value={currentLead.full_name} label="Nombre" id="outlined-size-normal" defaultValue="Normal" /> 
          </Box>
          <Box style={textFieldBoxStyle}>
            <TextField style={textFieldStyle} value={currentLead.email} label="Email" id="outlined-size-normal" defaultValue="Normal" /> 
          </Box>
          <Box style={textFieldBoxStyle}>
              <TextField style={textFieldStyle} value={currentLead.phone_number} label="Teléfono" id="outlined-size-normal" defaultValue="Normal" />         
          </Box>
          <Box style={textFieldBoxStyle}>
            <TextField
              style={textFieldStyle}
              id="outlined-multiline-static"
              label="Comentarios"
              multiline
              rows={4}
              defaultValue="Default Value"
            />
          </Box>
          <Box style={textFieldBoxStyle}>
            <FormControl style={leadFormControlStyle} >
              <InputLabel id="test-select-label">Pîxel</InputLabel>
              <Select
                  style={textFieldStyle}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPixel}
                  label="Pixel"
                  onChange={handleChangeSelectedPixel}
                >
                { pixelsList.map( (pixel) => (
                  <MenuItem value={pixel.id}>{pixel.name}</MenuItem>
                )) } 
              </Select>
            </FormControl>
          </Box>
          <Box style={textFieldBoxStyle}>
            <FormControl style={leadFormControlStyle} >
              <InputLabel id="test-select-label">Event Name</InputLabel>
              <Select
                  style={textFieldStyle}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedEventName}
                  label="Event Name"
                  onChange={handleChangeSelectedEventName}
                >
                  {resultCodeList.map((code) => (
                    <MenuItem value={code.codigo}>{code.descripcion}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box style={textFieldBoxStyle}>
            <Button onClick={sendLead} disabled={checkFields()}  variant="contained" color="primary">Aplicar</Button>
          </Box> 
            
        </Grid> : ''}
      </Grid> 
    </>
  );
}

export default Leads;
