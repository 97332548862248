import React, { useState } from 'react';
import { Layout, Menu, Card, Switch } from 'antd';
import { Grid, InputAdornment, TextField , Box, Button } from '@mui/material';
import Api from '../../state/Api';
import Accordion from './Accordion';

const { Header, Content, Footer } = Layout;

const Busqueda = (props) => {
    const [ apellido, setApelido] = useState('');
    const [ nombres, setNombres] = useState('');
    const [ nroDocu, setNroDocu] = useState('');
    const [ telefono, setTelefono] = useState('');
    

    const buscar = async () => {
        let paramsQuery = {};
        if(apellido){ paramsQuery.apellido = apellido };
        if(nombres) { paramsQuery.nombres = nombres };
        if(nroDocu){ paramsQuery.nroDocu = nroDocu };
        if(telefono) { paramsQuery.telefono = telefono }
        const { data } = await Api.req.get(`/customers/search`,{
            params:paramsQuery,
        });
        props.onBusquedaResult(data.items)
    }


    return (
        <div style={{ boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 10%)', border: '#ddd 1px solid', padding: 15, borderRadius: 8, backgroundColor: '#fff' }} >
            <Grid container spacing={1} xs={12} s={12} style={{ boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 10%)', border: '#ddd 1px solid', padding: 15, borderRadius: 8, backgroundColor: '#fff' }} >
                <Grid item xs={12}>
                    <h3>Set up your account</h3>
                </Grid>
                <Grid item xs={12}>
                    <Accordion />
                </Grid>
            </Grid>
        </div>
    )
}

export default Busqueda;