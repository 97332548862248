import React ,{ useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import DrawerApp from './Drawer'
import AppBarApp from './AppBar'
import Login from '../Login';
import Protected from './Protected';
import OnlyLoggedIn from './OnlyLogged';
import RedirectDefault from './RedirectDefault';
import Api from '../../state/Api';
import Dashboard from '../Dashboard';


import { useSelector, useDispatch } from 'react-redux';
import { HashRouter, Routes, Route } from "react-router-dom";
import ToastContainer from './../Toast/Container';
import Toast from '../Toast/Toast';
import * as storeSelectors from '../../state/leads/selectors'
import * as authSelectors from './../../state/auth/selectors';
import * as authActions from './../../state/auth/actions';
import * as appSelectors from './../../state/app/selectors'
import * as skusSelector from './../../state/skus/selectors';
import * as ordersSelectors from './../../state/orders/selectors';
//import { getTokenFirebase, leMessaging } from '../../firebase';
//import { onMessage } from "firebase/messaging";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Leads from '../Leads';

const { api } = window;

export default function App (props) {
  const [open, setOpen] = React.useState(false);
  const toasts = useSelector(appSelectors.getToasts());
  const isLoggedIn = useSelector(() => localStorage.getItem('jwt') || false)
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();
  //getTokenFirebase(setTokenFound)

  const { jwt } = window.localStorage;
  if (jwt) {
    Api.setJWT(jwt);
  }

  useEffect(() =>{

    /*
    api.receiveNotifications(window.start_notifications, (args) => {
      console.log(args)
      //recibo objecto de Fcm en react
    });*/
    dispatch(authActions.initApp)
  },[])

  /*
  onMessage(leMessaging, (payload) => {
    console.log(payload)
  }); */ 

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // #f6f7fb
  return (
        <Box sx={{ display: 'flex', height: 'calc(100vh)' }}>
          <Backdrop
            sx={{ color: '#fff', position :'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
            
          <HashRouter basename='/'>
            <OnlyLoggedIn isLoggedIn={isLoggedIn}> <AppBarApp position="sticky" open={open} setOpen={setOpen} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} /></OnlyLoggedIn>
            <OnlyLoggedIn isLoggedIn={isLoggedIn}><DrawerApp variant="permanent" open={open} setOpen={setOpen} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/></OnlyLoggedIn>
            <Box component="main" id="main" sx={{ flexGrow: 1, p: 0, backgroundColor: '#f2f2f2', marginTop: isLoggedIn ? '64px':'0px', overflow: 'hidden'}} >
                <Routes>
                  <Route path="/*" element={<Protected isLoggedIn={isLoggedIn}><Dashboard/></Protected>} /> 
                  <Route path="/login" element={<RedirectDefault><Login/></RedirectDefault>} /> 
                  <Route path="/:formId/leads" element={<Protected isLoggedIn={isLoggedIn}><Leads/></Protected>} /> 
                </Routes>
            </Box>
          </HashRouter>
          <ToastContainer>
                {toasts.map((content, index) => (
                  <Toast type={content.type} index={index} id={content.id} key={content.id}>{content.toast}</Toast>
                ))}
          </ToastContainer>
        </Box>
  )

}