import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FormControl from '@mui/material/FormControl';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Api from '../../state/Api';
import * as leadsActions from './../../state/leads/actions';


const steps = ['Login', 'Select Page', 'Select Form'];

export default function SimpleAccordion() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedFB, setIsLoggedFB] = React.useState(false);

    const [FbPagesList, setFbPagesList] = React.useState([]);
    const [FbFormsList, setFbFormsList] = React.useState([]);
    const [selectedForm, setSelectedForm ] = React.useState(false);
    const [GlobalTypesList, setGlobalTypesList] = React.useState([]);
    const [selectedGlobalType, setSelectedGlobalType] = React.useState(false);
    const [selectedPage, setSelectedPage ] = React.useState(null)
    const [selectedAccessToken, setSelectedAccessToken ] = React.useState(null)


    const [activeStep, setActiveStep] = React.useState(1);
    const [skipped, setSkipped] = React.useState(new Set());
  
    const isStepOptional = (step) => {
      return step === 9;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const filterPages = (pages) => {
      setFbPagesList(pages)
      if(pages.length > 0){
        handleNext()
      }
    }  

    const handlePageClick = (pagina) => {
      dispatch(leadsActions.saveSelectedPage(pagina))
      setSelectedPage(pagina)
      handleLeadForms(pagina)   
    }

    
    const handleFormClick = async (form) => {
      setSelectedForm(form) 
      const { data }  = await Api.req.get(`global/types/`);
      setGlobalTypesList(data.data)
      handleNext()
    }

    const handleGlobalClick = (globalType) => {
      setSelectedGlobalType(globalType)
    }    

    const getLeads = async (form) => {
      let paramsData = {
        "nombre": selectedForm.name ,
        "appid": '2000772723322124',
        "pageid": selectedPage.id ,
        "accessToken": selectedAccessToken ,
        "formid": selectedForm.id ,
        "type": selectedGlobalType.id
      }
      const configData = await Api.req.post(`https://leads.tevecompras.com/campaigns/external/configure`, paramsData );
      dispatch(leadsActions.saveCampaignId(configData.data.campaigns[0].campaignid))
      dispatch(leadsActions.formComplete())
      navigate(form.id + '/leads');
    }

    const handleFbLogin = () => {
      if(!isLoggedFB){
        window.FB.login(function(response) {
          if (response.authResponse) {
            setIsLoggedFB(true)

            window.FB.api('/me/accounts?fields=picture,instagram_business_account{name,username},access_token,id,name,username', function(response) {
              console.log(response.data)
              filterPages(response.data)
              // setAccessToken(response.data.access_token)
            });
          } else {
           console.log('User cancelled login or did not fully authorize.');
          }
        }, {scope: 'pages_show_list,public_profile,email'});
      }else{
        //setOpenModalLogout(true);
      }
      
    }

    const handleLeadForms = (selected) => {
      setSelectedAccessToken(selected.access_token)
      window.FB.api(`/${selected.id}?access_token=${selected.access_token}&fields=business,leadgen_forms`, function(res) {
        // console.log(res.leadgen_forms.data)
        localStorage.setItem('businessId', res.business.id);
        let data = []
        data = res.leadgen_forms.data
        setFbFormsList(data)
        handleNext()
      })
    };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            
          </Box>
        </React.Fragment>
      )}
    </Box>
      <Accordion id="step-1" disabled={activeStep === 0} expanded={activeStep === 1 ? true : false }>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Connect Meta Account {activeStep}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Button onClick={handleFbLogin} variant="contained">Login to Meta</Button>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion id="step-2" disabled={activeStep === 1} expanded={activeStep  === 2} >
        <AccordionSummary
            disabled={activeStep === 1}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
        >
        <Typography>Select Brand Account / Page </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Pages</InputLabel> */}
                <List>
                {FbPagesList.map((pagina,index) => (
                  
                  <ListItem button onClick={() => handlePageClick(pagina)} key={index}>
                    <ListItemIcon>
                      <img alt="" src={pagina.picture.data.url} />
                    </ListItemIcon>
                    <ListItemText  secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="primary">
                          {pagina.name}
                        </Typography>
                      </React.Fragment>
                    } 
                    />
                  </ListItem>
                ))}
              </List>
            </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion id="step-3" disabled={activeStep === 2} expanded={activeStep === 3} >
        <AccordionSummary
            disabled={activeStep === 2}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
        >
        <Typography>Select Lead Forms </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Pages</InputLabel> */}
                <List>
                {FbFormsList.map((form,index) => (
                  
                  <ListItem style={selectedForm.id === form.id ? { backgroundColor: '#d9d9d9' } : {}} button onClick={() => handleFormClick(form)} key={index}>
                    <ListItemIcon>
                      <FacebookIcon />
                    </ListItemIcon>
                    <ListItemText control={<Radio />} primary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="primary">
                          {form.name}
                        </Typography>
                      </React.Fragment>
                    } 
                    />
                  </ListItem>
                ))}
              </List>

              
            </FormControl>  
        </AccordionDetails>
      </Accordion>
      <Accordion id="step-4" disabled={activeStep === 3} expanded={activeStep === 4} >
          <AccordionSummary
            disabled={activeStep === 3}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Select Global Type </Typography>
          </AccordionSummary>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Pages</InputLabel> */}
            <List>
              {GlobalTypesList.map((type, index) => (

                <ListItem style={selectedGlobalType.id === type.id ? { backgroundColor: '#d9d9d9' } : {}} button onClick={() => handleGlobalClick(type)} key={index}>
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                  <ListItemText control={<Radio />} primary={
                    <React.Fragment>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="primary">
                        {type.name}
                      </Typography>
                    </React.Fragment>
                  }
                  />
                </ListItem>
              ))}
            </List>
          </FormControl>  
          {selectedGlobalType ? <Button fullWidth onClick={() => getLeads(selectedForm)} variant="contained">CONFIRM CONFIGURATION</Button> : ''} 
      </Accordion>
    </div>
  );
}