import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { Badge } from '@mui/material';
import LogoutDialog from './LogoutDialog';
import FacebookIcon from '@mui/icons-material/Facebook';
import * as leadsSelectors from './../../state/leads/selectors'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as leadActions from "../../state/leads/actions";
import * as authActions from './../../state/auth/actions';
import * as authSelectors from './../../state/auth/selectors';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBarApp(props) {
//   const theme = props.theme;
//   const classes = useStyles();
  const dispatch = useDispatch();
  const open = props.open;
  const handleDrawerClose = props.handleDrawerClose;
  const handleDrawerOpen = props.handleDrawerOpen;
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openModalLogout, setOpenModalLogout] = React.useState(false);
  const formComplete = useSelector(leadsSelectors.getFormComplete());
  const loggedIn = useSelector(authSelectors.getLoggedIn());

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseDialog = () => {
    setOpenModalLogout(false)
  }
 

  
  const handleCloseLogout = () => {
    window.FB.logout(function(response) {
      setOpenModalLogout(false)
      dispatch(leadActions.formIncomplete())
      navigate('/login')
    });
  }

  const handleFBLogin = () => {
    setOpenModalLogout(true)
  }

  const handleLogoutApp = () => {
    dispatch(authActions.logout())
    navigate('/login')
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  return (
    <div>
    <LogoutDialog open={openModalLogout} onClose={handleCloseDialog} onCloseLogout={handleCloseLogout} > </LogoutDialog>
    <CssBaseline />
      <AppBar 
        position="fixed"
        open={open}
        style={{ backgroundColor: '#18202c' }}
      >
      
        <Toolbar style={{ backgroundColor: '#18202c', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 0 , width: '33%'}}>
          </Box>
          <Box sx={{ flexGrow: 1 , textAlign: 'center'}}>
            <img src='./assets/logo-tevecompras-title.png' style={{height: '40px', overflow: 'hidden' }} />
          </Box>          
          <Box sx={{ flexGrow: 0, width: '33%', flexDirection: 'row', textAlign: 'right' }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="info">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton>
               {formComplete ? <Button variant="contained" color="primary" onClick={handleFBLogin}> <FacebookIcon /> FB Logout</Button>  : '' }  
              </IconButton>
            {loggedIn ? <IconButton><Button variant="outlined" color="error" onClick={handleLogoutApp}>Logout</Button></IconButton> : '' }
          </Box>          
        </Toolbar>
      </AppBar>
    </div>
  );
}