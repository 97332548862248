
export const DEFAULT_TITLE = 'TeVeCompras CRM';

export const SITE_URL = 'https://leads.tevecompras.com/';
export const API_URL = SITE_URL;

export const SOCKET_ACTIONS = {
  CONTACTS: {
    NEW_CONTACT: 'contacts:contact:new',
    INCOMING_MESSAGE: 'contacts:message:incoming',
    MESSAGE_ACK: 'contacts:message:ack',
    MESSAGE_READ: 'contacts:contact:message:read',
    MESSAGE_STATUS: 'messageStatus',
    MESSAGE_DELIVERED : 'contacts:contact:message:delivered',
    MESSAGE_REJECTED : 'contacts:contact:message:rejected'
  },

  CHANNELS: {
    CM_CREATED: 'chat:channelmessage:created',
    CHANNEL_UPDATED: 'chat:channel:updated',
    CHANNEL_TYPING: 'chat:channel:typing',
  },

  AUTH: {
    FORCED_DISCONNECT: 'auth:forcedLogout'
  },

  ALERTS: {
    ALERT_CREATED: 'alerts:created',
    ALERT_UPDATED: 'alerts:updated',
  },

  USERS: {
    ONLINE_LIST: 'users:online:list',
    ONLINE: 'users:online:new',
    OFFLINE: 'users:offline:new',
  },
};

export const FIREBASE = {
  MESSAGING_SENDER_ID: '808247182873',
};

export const APP_NAMES = {
  web: 'tevecompras-fcm',
};

export const SW = {
  NEW_AUTH: 'notifications/newAuth',
  NEW_THREADS: 'notifications/newThreads',
  NEW_REPLIES: 'notifications/newReplies',
};
