import React, { useState } from "react";
// import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../state/auth/actions';
import * as authSelectors from '../../state/auth/selectors';

import "./Login.css";
import { Button, Card, CardContent, CardMedia, CardActions, Typography, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ModalTerminos from "../shared/modalTerminos";
import { padding } from "@mui/system";

const Login = ( props ) => {

  // const classes = props.classes;

  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [telefono, setTelefono] = useState('');
  const [clave, setClave] = useState('');
  const [requireClave, setRequireClave] = useState(false);
  const dispatch = useDispatch();
  const loginError = useSelector(authSelectors.getLoginError());
  const [openModalTerminos, setOpenModalTerminos ] = useState(false);
  const [language, setLanguage] = useState('spanish');

  const login = async () => {
    try {
      setLoggingIn(true);
      const data = await dispatch(authActions.login(username, password));
      console.log('ah')
      setLoggingIn(false);
      navigate('/')
    }
    catch (error) {
      setLoggingIn(false);
    }
  };  



  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const validLogin = () => {
    if(username.length > 0 && password.length > 0){
      return false
    }else{
      return true;
    }
  }

  const maxLengthCheck = (object) => {
    let maxLength = 6;
    if (object.target.value.length > maxLength) {
      object.target.value = object.target.value.slice(0, maxLength)
    }
  }

  const handleCloseTerminos = () => {
    setOpenModalTerminos(false)
  }

  const openTerminos = (idioma) => {
    setLanguage(idioma)
    setOpenModalTerminos(true)
  }


  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', height: "calc(100vh - 65px)"}} >
      <ModalTerminos language={language} open={openModalTerminos} onClose={handleCloseTerminos} />
        <form  noValidate autoComplete="off">            
          <Card style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 40, paddingTop: 20, borderRadius: 20 }} >
            {/* <CardHeader title="Login" /> */}
            <CardMedia 
              style={{ height: 106, width: 200 , margin : '0 auto' , marginTop : 10}}
              title="TeVeCompras Omnichannel"
              image="https://api.tevecompras.com/assets/logo-tevecompras.png"
            />
            <CardContent style={{  display: 'flex', flexDirection: 'column', justifyContent:'space-evenly', alignItems: 'center', padding: 40, height: 300 }} > 
              
                <>
                  <TextField 
                    label="Usuario"
                    onChange={({ target: { value } }) => setUsername(value)}
                    disabled={loggingIn}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                  />
                  <TextField 
                    label="Contraseña"
                    // placeholder="Contraseña"
                    type="password"
                    onChange={({ target: { value } }) => setPassword(value)}
                    disabled={loggingIn}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                  />
                  <Button 
                    style={{width: 150}}
                    color="primary"
                    onClick={login}
                    disabled={validLogin()}
                    variant="contained"
                  >Login</Button>
                </>
                {loginError && (
                  <Typography>
                    {loginError}
                  </Typography>
                )}
            </CardContent>
            </Card>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 auto', padding: 10}} >
            <Button  onClick={ () =>{ openTerminos('spanish') } }>
              Política de Privacidad
            </Button>
            <Button onClick={  () =>{openTerminos('english') } }>
              Privacy Policy
            </Button>
            </div>
          </form>
    </div>
  );
  
}

export default Login;
