import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';

const LogoutDialog = (props) => {

    const handleClose = () => {
      props.onClose();
    };
  
    const handleLogOut = () => {
      props.onCloseLogout();
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
        <DialogTitle id="simple-dialog-title">Confirma desloguearse de Facebook</DialogTitle>
        <List>
            <ListItem button onClick={() => handleLogOut()}>
                <ListItemText primary="SI" />
            </ListItem>
            <ListItem button onClick={() => handleClose()} >
                <ListItemText primary="NO" />
            </ListItem>
        </List>
      </Dialog>
    );
  }
  
  LogoutDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCloseLogout: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  

  export default LogoutDialog;