import { FETCH_LEADS_REQUEST, FETCH_LEADS_SUCCESS, FETCH_LEADS_FAILURE, SAVE_SELECTED_PAGE, SET_FORM_COMPLETE , SET_FORM_INCOMPLETE, SAVE_CAMPAIGN_ID } from './constants';
import Api from '../Api';

export const formIncomplete = () => async (dispatch) => {
    dispatch({ type: SET_FORM_INCOMPLETE })
}

export const formComplete = () => async (dispatch) => {
    dispatch({ type: SET_FORM_COMPLETE })
}

export const fetchLeads = (formId, pageId, estado) => async (dispatch) => {
    dispatch({ type: FETCH_LEADS_REQUEST })
    let access_token =  localStorage.getItem('access_token');
    try{
        const { data } = await Api.req.get(`leads/${pageId}/${formId}/${estado}/leads`);
        dispatch({ type: FETCH_LEADS_SUCCESS, payload: { data: data.data, formId: formId } })
    }catch(error){
        dispatch({ type: FETCH_LEADS_FAILURE })
    }
    
    /*
    window.FB.api( formId + '/leads?access_token=' + access_token, function(response) {
        if (!response || response.error) {
            dispatch({ type: FETCH_LEADS_FAILURE })
            } else {
                console.log('pasaXSuccess')
            dispatch({ type: FETCH_LEADS_SUCCESS, payload: { data : response.data , formId : formId  }})
        }
        
    })*/
}

export const saveSelectedPage = (page) => async (dispatch) => {
    dispatch({ type: SAVE_SELECTED_PAGE , payload : page })
}

export const saveCampaignId = (campaignId) => async (dispatch) => {
    dispatch({ type: SAVE_CAMPAIGN_ID, payload: campaignId })
}